<template>
  <div class="main_index">
    <div class="top_manu">
      <div class="top_content">
        <div class="top_left">
<!--          <a href="/"><img class="image" :src="logo" alt=""/></a>-->
          <a href="/"><img class="image" src="https://dhyimage.oss-cn-zhangjiakou.aliyuncs.com/logo-hykj.png" alt=""/></a>
        </div>
        <div class="top_right">
          <!-- 导航数据 -->
          <ul class="top_right_manu">
            <li v-for="(item, index) in topmenulist" :key="index + new Date()">
              <a v-if="item.path.startsWith('http')" :href="item.path" style="font-weight: 700;" target="_blank">{{item.name}}</a>
              <router-link v-else :style="{color:active==index?'rgb(249, 145, 0)':''}" @click.native="activeItem(index)" style="font-weight: 700;" :to="{ path: item.path}">
                {{ item.name }}
              </router-link>
            </li>
            <li><a target="_blank" :href="bsUrl" class="btn-login">登录</a></li>
<!--            <li><router-link :to="{path:'/sysRegister'}" class="btn-register">注册</router-link></li>-->
          </ul>
        </div>
      </div>
    </div>

    <router-view style="padding-top:80px;" :key="randomId"/>

    <el-backtop target=".main_index">
<!--      <div style="cursor: pointer" class="coin">-->
<!--        <img src="../../static/切图文件/img/icon-01.png" alt=""/>-->
<!--      </div>-->
<!--      <div id="second" class="coin">-->
<!--        <img src="../../static/切图文件/img/icon-02.png" alt=""/>-->
<!--      </div>-->
<!--      <div id="third" class="coin">-->
<!--        <img src="../../static/切图文件/img/icon-03.png" alt=""/>-->
<!--      </div>-->
<!--      <div class="telephone animate__animated animate__fadeInDown">-->
<!--        <img :src="imgsrc+contact" alt=""/>-->
<!--      </div>-->
<!--      <div class="rqcode animate__animated animate__fadeInLeft">-->
<!--        <img :src="imgsrc+qrcode" alt=""/>-->
<!--      </div>-->
    </el-backtop>

    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../components/footer";
import {Backtop} from 'element-ui'
import {getLogo, getMenuList} from "../request/index";

export default {
  components: {
    elBacktop: Backtop,
    Footer
  },
  data() {
    return {
      bsUrl:window.apiUrl.replaceAll("/prod-api",""),
      active: 0,
      menu: {},
      bannerImgs: [],
      topmenulist: [],
      logo: '',
      contact: "",
      qrcode: ""
    };
  },
  created() {
    getMenuList({parentId: 0}).then(res => {
      this.topmenulist = res.data;
      let newVal = this.$route.path;
      newVal = "/"+newVal.split("/")[1];
      this.active=-1;
      for (let i = 0; i <this.topmenulist.length; i++) {
        if(this.topmenulist[i].path == newVal){
          this.active = i;
          return;
        }
      }
    });
    getLogo().then(res => {
      this.logo = res.msg;
    });
  },
  methods: {
    activeItem(index){
      this.active = index;
    }
  },
  computed: {
    randomId() {
      return this.$route.fullPath + new Date();
    }
  },
  mounted() {
  },
  watch: {
    '$route.path': function (newVal){
      newVal = "/"+newVal.split("/")[1];
      this.active=-1;
      for (let i = 0; i <this.topmenulist.length; i++) {
        if(this.topmenulist[i].path == newVal){
          this.active = i;
          return;
        }
      }
    }
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.main_index {
  height: 100%;
  overflow-x: hidden;
}

.top_manu {
  width: 100%;
  height: 80px;
  min-width: 1120px;
  background-color: #316182;
  color: white;
  position: fixed;
  z-index: 100;
  padding:0 10px;
}

.top_content {
  margin: 0 auto;
  /*width: 1200px;*/
  height: 80px;
}

.top_right_manu {
  list-style: none;
}

.top_left {
  /* display: inline-block; */
  margin-top: 10px;
  float: left;
  vertical-align: top;
  margin-right: 20px;
}

.top_right {
  /* display: inline-block; */
  line-height: 80px;
  float: right;
  vertical-align: top;
}

.top_right_manu > li {
  float: left;
  margin-right: 10px;
}

.image {
  display: inline-block;
  line-height: 50px;
  height: 50px;
  margin-top: 5px;
}
.top_left a {
  display: flex;
  line-height: 60px;
  height: 60px;
  font-size: 30px;
  color: white;
  text-decoration: none
}

.top_right .top_right_manu li > a {
  color: white;
  text-decoration: none;
}

.top_right .top_right_manu li > a:link {
  color: white;
  text-decoration: none;
}

/* .top_right .top_right_manu li>a:visited{
  color: rgb(249, 145, 0);
  text-decoration: none;
} */
.top_right .top_right_manu li > a:hover {
  color: rgb(249, 145, 0);
  text-decoration: none;
}

/* .top_right .top_right_manu li>a:active{
  color: rgb(249, 145, 0);
  text-decoration: none;
} */

.ywx_content_line {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ywx_content_line1 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.lbimage {
  height: 546px;
  min-width: 1200px;
  position: relative;
}

.lbimage .lbcontent {
  text-align: center;
  width: 1200px;
  position: absolute;
  left: 50%;
  top: 150px;
  transform: translate(-600px);
  z-index: 99;
}

.lbcontent .lbbutton {
  margin-top: 30px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.middlemenu {
  width: 100%;
  height: auto;
}

.middlemenu {
  margin-top: 30px;
  background: url("../../static/切图文件/nav/bg-map-1.png") no-repeat top center;
}

.ywmenu {
  margin: 0 auto;
}

.ywmenu > ul > li {
  display: inline-block;
  margin-right: 10px;
}

.ywcontent {
  margin: 0 auto;
  width: 1200px;
  margin-top: 10px;
  height: auto;
  /* text-align: center; */
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}

.ywcontent .ywx_content {
  text-align: center;
  width: 588px;
  height: 200px;
  display: inline-block;
  /* border: 1px solid #dddddd; */
  margin-top: 20px;
  background: url("../../static/切图文件/sbg.jpg") no-repeat;
  box-shadow: 0 0 5px 1px orange;
}

.ywcontent .ywx_content:hover {
  background: url("../../static/切图文件/cbg.png") no-repeat;
  box-shadow: 0 0 5px 2px #3366ff;
  /* background-position-x: -14px;
  background-position-y: -14px; */
  border: none;
  cursor: pointer;
}

.ywcontent .ywx_content .hthtml {
  margin-left: 0;
}

/* 奇数 */
.ywcontent .ywx_content:nth-of-type(old) {
  margin-right: 20px;
}

/* 偶数 */
.ywcontent .ywx_content:nth-of-type(even) {
  margin-left: 20px;
}

.news .newscontent {
  margin: 0 auto;
  width: 1200px;
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  vertical-align: top;
}

.bttitle {
  /* margin-top: 30px; */
  font-size: 26px;
  font-weight: 900;
  color: rgb(249, 145, 0);
  display: inline-block;
}

.imageww {
  display: inline-block;
  vertical-align: text-bottom;
}

.news .newscontent #xiashang {
  display: inline-block;
  width: 358px;
  height: 234px;
  margin-bottom: 40px;
  border: 1px solid #dddddd;
}

.ywcontent .ywx_content p {
  margin-left: 0;
  margin-right: 0;
}

.news .newscontent #xiashang .mdimgcontent {
  width: 358px;
  height: 180px;
  position: relative;
}

.news .newscontent #xiashang .dw {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 99;
  text-align: left;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.morenews {
  width: 100%;
  /* height: 350px; */
  min-width: 1200px;
  background-color: #f7f7f7; /* rgb(81, 85, 88) */
  margin-top: 50px;

  position: relative;
}

.morenews .dwbtn {
  width: 120px;
  height: 40px;
  position: absolute;
  top: -20px;
  left: 50%;
  text-align: center;
  transform: translate(-50px);
}

.morenews .xtb {
  padding-top: 70px;
}

.morenews .xtb p {
  color: black;
  font-weight: 900;
}

.morenews .hezuo {
  margin: 20px auto 0 auto;
  width: 1200px;
  padding-bottom: 50px;
  /* height: 200px; */
}

.logo img {
  height: 60px;
  margin-bottom: 30px;
}

.news .newscontent #xiashang:hover {
  box-shadow: 0 0 20px #aaa;
}

.coin {
  width: 90px;
  height: 90px;
  background-color: coral;
  margin-top: 3px;
  box-shadow: 2px 2px 20px #888;
}

.coin img {
  width: 90px;
  height: 90px;
}

.telephone {
  position: absolute;
  width: 140px;
  height: 30px;
  /* background-color: black;   */
  top: 85px;
  right: 90px;
  z-index: 99;
  display: none;
  /* border: 1px solid #dddddd; */
}

/* .telephone p{
    color: black;
    font-weight: 900;
} */
.telephone img {
  width: 120px;
  height: 120px;
}

.rqcode {
  position: absolute;
  width: 120px;
  height: 30px;
  top: 170px;
  right: 100px;
  z-index: 100;
  display: none;
}

.rqcode img {
  width: 120px;
  height: 120px;
}

#third:hover ~ .rqcode {
  display: block;
}

#second:hover ~ .telephone {
  display: block;
}

/*.el-backtop:hover {*/
/*  background-color: rgba(0,0,0,0) !important;*/
/*}*/

/*.el-backtop {*/
/*  background-color: rgba(0,0,0,0) !important;*/
/*  border-radius: 0 !important;*/
/*  width: auto !important;*/
/*  height: auto !important;*/
/*  display: block !important;*/
/*}*/
.btn-login {
  margin-left: 20px;
  padding: 8px 15px;
  background: #0984e3;
  color: #ffffffff;
  border-radius: 20px;
  cursor:pointer;
}
.btn-register {
  border: 1px solid #0984e3;
  color: #0984e3;
  padding:8px 15px;
  border-radius: 20px;
  cursor:pointer;
}
</style>